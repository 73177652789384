import React from 'react';
import logoMJM from "../../../assets/images/logos/partners/logo-mjm.png";

const SectionPublications = () => {
    return (
        <section className="bg-[#F9FDFB] py-12">
            <div className="container mx-auto px-4 text-center">
                <h2 className="text-3xl md:text-4xl font-semibold text-[#04200F] mb-10">Nos parutions</h2>

                <div className="flex flex-col items-center justify-center gap-10 md:flex-row md:justify-around md:gap-6">
                    <a
                        href="https://maisonetjardinmagazine.fr/2025/03/24/vivez-linstant-parfait-dans-une-authentique-maison-en-champagne/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={logoMJM}
                            alt="Maison & Jardin"
                            className="h-32 md:h-32 object-contain opacity-60 transition-opacity duration-200 hover:opacity-100"
                        />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default SectionPublications;