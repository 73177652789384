import React, { useCallback, useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import imageHeaderBanner from "./../../assets/images/global/imageHeaderBannerReservation.png";
import DescriptionSplit from "../../components/Description/description-split";
import Select from "../../components/Inputs/select";
import Hr from "../../components/Style/hr";
import H2 from "../../components/Titles/h2";
import H3 from "../../components/Titles/h3";
import H4 from "../../components/Titles/h4";
import H5 from "../../components/Titles/h5";
import H6 from "../../components/Titles/h6";
import Button from "../../components/Buttons/button";
import Api from "../../services/api.service";
import AccommodationDisplayed from "./components/accommodation-displayed";
import ListOfServices from "./components/list-of-services";
import PersonnalInfoForm from "../../components/Forms/personnal-info-form";
import BackgroundImage from "../../components/Banner/background-header";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Helmet } from "react-helmet";
import lodash from "lodash";
import Validator from "../../services/validator.service";
import Helper from "../../services/helper.service";
import DateFieldInput from "../../components/Inputs/date-field";
import * as _ from "lodash";
import Icon from "../../components/Images/icon";
import iconCalendar from "../../assets/icons/reservation/calendar.svg";
import iconUsers from "../../assets/icons/reservation/users.svg";
import iconAccommodation from "../../assets/icons/reservation/accommodation.svg";
import iconServices from "../../assets/icons/reservation/services.svg";

export default function Reservation() {
    const { t, i18n } = useTranslation();
    // const navigate = useNavigate();
    var touristTax = 3.3;

    const fixedColumnRef = useRef(null);
    const [divHeight, setDivHeight] = useState(0);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const calculateDivHeight = () => {
        if (fixedColumnRef.current) {
            return fixedColumnRef.current.getBoundingClientRect().height;
        }
        return 0;
    };


    // Update the height once the component mounts
    useEffect(() => {
        // Calculate and set the div height on mount
        const height = calculateDivHeight();
        setDivHeight(height);
        console.log("Fixed column height:", height);

        // Function to update the screen height state
        const updateScreenHeight = () => {
            setScreenHeight(window.innerHeight);
        };

        // Update screen height on mount
        updateScreenHeight();

        // Add event listener for window resize to update screen height
        window.addEventListener('resize', updateScreenHeight);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', updateScreenHeight);
        };
    }, []);

    const getInitialState = () => {
        const stayDatas = Api.getStayDatas();
        const storedStayDates = localStorage.getItem('stayDates');
        const storedNbAdults = localStorage.getItem('nbAdults');
        const storedNbChilds = localStorage.getItem('nbChilds');
        const storedAccommodationSelected = localStorage.getItem('accommodationSelected');
        const storedServicesSelected = localStorage.getItem('servicesSelected');
        const storedPersonnalInfoFormData = localStorage.getItem('personnalInfoFormData');

        /*** CHECK DATES  ***/
        const tomorrow = new Date();
        tomorrow.setHours(0, 0, 0, 0);
        tomorrow.setDate(tomorrow.getDate() + 1);

        const defaultStayDates = {
            // startDate: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
            // endDate: moment(new Date()).add(3, 'days').format('YYYY-MM-DD')
            startDate: null,
            endDate: null
        };

        let stayDates = storedStayDates ? JSON.parse(storedStayDates) : stayDatas.stayDates || defaultStayDates;

        if (new Date(stayDates.startDate) < tomorrow || new Date(stayDates.endDate) < tomorrow) {
            stayDates = defaultStayDates;
        }

        const nbPersons = _.get(stayDatas, "nbAdults", 0) + _.get(stayDatas, "nbChilds", 0);

        let tmpAccommodationSelected = "";

        if (storedAccommodationSelected && !_.isArray(JSON.parse(storedAccommodationSelected))) {
            tmpAccommodationSelected = JSON.parse(storedAccommodationSelected) || "";
        }

        return {
            stayDates: stayDates,
            nbAdults: Number(storedNbAdults) || nbPersons || "",
            nbChilds: Number(storedNbChilds) || "",
            accommodationSelected: tmpAccommodationSelected,
            servicesSelected: storedServicesSelected ? JSON.parse(storedServicesSelected) : [],
            personnalInfoFormData: storedPersonnalInfoFormData ? JSON.parse(storedPersonnalInfoFormData) : {
                lastname: "",
                firstname: "",
                email: "",
                phone: ""
            }
        };
    };

    const [stayDates, setStayDates] = useState(getInitialState().stayDates);
    const [nbAdults, setNbAdults] = useState(getInitialState().nbAdults);
    const [nbChilds, setNbChilds] = useState(getInitialState().nbChilds);
    const [displayedAccommodation] = useState(['domain', 'home', 'annex']);
    const [accommodationPrice, setAccommodationPrice] = useState(0);
    const [discountLongAccommodation, setDiscountLongAccommodation] = useState(0);
    const [accommodationSelected, setAccommodationSelected] = useState(getInitialState().accommodationSelected);
    const [servicesSelected, setServicesSelected] = useState(getInitialState().servicesSelected);
    const [personnalInfoFormData, setPersonnalInfoFormData] = useState(getInitialState().personnalInfoFormData);
    const [blockedDates, setBlockedDates] = useState([]);
    const [prices, setPrices] = useState({
        annex: { price: 0, listOfDiscountForLongReservation: [], cleaning: 0 },
        home: { price: 0, listOfDiscountForLongReservation: [], cleaning: 0 },
        domain: { price: 0, listOfDiscountForLongReservation: [], cleaning: 0 }
    });
    const [services, setServices] = useState([]);
    const [displayFormContactDetails, setDisplayFormContactDetails] = useState(false);
    const [errorsPersonnalInfo, setErrorsPersonnalInfo] = useState({});

    const maxPeopleDomain = 22;
    const maxPeopleHome = 12;
    const maxPeopleAnnex = 10;

    const updatePersonnalInfoFormData = (newData) => { setPersonnalInfoFormData(newData) };

    const getNbDaysStay = useCallback(() => {
        const days = moment(stayDates.endDate).diff(stayDates.startDate, 'days');
        return lodash.isNaN(days) ? 0 : days;
    }, [stayDates]);

    const getPricesForDates = useCallback(async () => {
        const response = await Api.getPricesForDates(stayDates.startDate, stayDates.endDate, accommodationSelected);
        const res = response.data;

        if (_.get(response, 'data.status', false)) {
            const totalAccommodationPrice = _.get(res, 'data.accommodationPrice');
            if (!totalAccommodationPrice) return;

            const dailyAccommodationPrice = totalAccommodationPrice / getNbDaysStay();
            if (!dailyAccommodationPrice) return;

            setPrices(prevPrices => ({
                ...prevPrices,
                [accommodationSelected]: {
                    ...prevPrices[accommodationSelected],
                    cleaning: _.get(res, 'data.cleaning', 0)
                }
            }));

            setAccommodationPrice(dailyAccommodationPrice);
            setDiscountLongAccommodation(_.get(res, 'data.discountLongAccommodation', 0));
        }
    }, [stayDates, accommodationSelected, getNbDaysStay]);

    const fetchWithRetry = async (apiCall, setter, defaultValue = {}, maxRetries = 3) => {
        let attempts = 0;
        while (attempts < maxRetries) {
            try {
                const response = await apiCall();
                if (response.data.status) {
                    setter(response.data.data ?? defaultValue); // Ensure default value if response.data.data is undefined
                    return;
                } else {
                    console.log(`Error (attempt ${attempts + 1}):`, response.data.error);
                }
            } catch (error) {
                console.error(`An error occurred (attempt ${attempts + 1}):`, error);
            }
            attempts++;
            if (attempts < maxRetries) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        }

        // If all attempts fail, set default value
        setter(defaultValue);
    };

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                fetchWithRetry(Api.getPrices, setPrices, {
                    annex: { price: 0, listOfDiscountForLongReservation: [], cleaning: 0 },
                    home: { price: 0, listOfDiscountForLongReservation: [], cleaning: 0 },
                    domain: { price: 0, listOfDiscountForLongReservation: [], cleaning: 0 }
                }),
                fetchWithRetry(Api.getAllServices, setServices, [])
            ]);
            setBlockedDates(Api.getBlockedDatesStored());
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (stayDates) {
            getPricesForDates();
        }
    }, [stayDates, accommodationSelected, getPricesForDates]);

    // Store data in localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('stayDates', JSON.stringify(stayDates));
    }, [stayDates]);

    useEffect(() => {
        localStorage.setItem('nbAdults', nbAdults);
    }, [nbAdults]);

    useEffect(() => {
        localStorage.setItem('nbChilds', nbChilds);
    }, [nbChilds]);

    useEffect(() => {
        localStorage.setItem('accommodationSelected', JSON.stringify(accommodationSelected));
    }, [accommodationSelected]);

    useEffect(() => {
        localStorage.setItem('servicesSelected', JSON.stringify(servicesSelected));
    }, [servicesSelected]);

    useEffect(() => {
        localStorage.setItem('personnalInfoFormData', JSON.stringify(personnalInfoFormData));
    }, [personnalInfoFormData]);

    const numberOptions = (labelKey, maxNumber = 22) => {
        return Array.from({ length: maxNumber + 1 }, (_, index) => {
            if (labelKey === "adult" && index === 0) {
                return null; // Exclude the option with value 0
            }
            return {
                value: `${index}`,
                label: `${index} ${t(`formInputs.${labelKey}`)}${index > 1 ? 's' : ''}`,
            };
        }).filter(option => option !== null);
    };

    const handleDateChange = (e) => {
        setStayDates(e.date);
    };

    const getPriceAccommodation = () => {
        if (!accommodationSelected) return 0;

        let price = prices[accommodationSelected].price;
        return displayNumberWithSpace(price);
    }

    const getSubTotalAccommodation = () => {
        if (!stayDates.startDate || !stayDates.endDate) return 0;

        const days = getNbDaysStay();
        const accommodationPriceWithoutSpaces = String(getPriceAccommodation()).replace(/\s/g, '');
        const accommodationPriceFloat = parseFloat(accommodationPriceWithoutSpaces);

        const amountStayCleaningFees = getPriceEndOfStayCleaningFees();
        const touristTax = getPriceTouristTax();

        const total = (days * accommodationPrice || accommodationPriceFloat) - discountLongAccommodation + amountStayCleaningFees + touristTax;

        if (lodash.isNaN(total)) {
            return NaN;
        }

        return displayNumberWithSpace(total);
    }

    const displayNumberWithSpace = (number) => {
        return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const getPriceEndOfStayCleaningFees = () => {
        if (!accommodationSelected) return 0;

        const price = prices[accommodationSelected]?.cleaning;
        return price;
    }

    const getPriceTouristTax = () => {
        const nbDays = getNbDaysStay();

        const startDate = new Date(stayDates.startDate);
        const thresholdDate = new Date('2026-01-01');
        touristTax = startDate >= thresholdDate ? 3.63 : 3.30;

        return Number((nbAdults * touristTax * nbDays).toFixed(2));
    };

    const getTotal = () => {
        // const accommodation = getSubTotalAccommodation().replace(/\s/g, '');
        let accommodation = getSubTotalAccommodation();
        if (!lodash.isNaN(accommodation) && accommodation !== 0) accommodation = accommodation.replace(/\s/g, '');

        const services = getSubTotalServices().replace(/\s/g, '');

        let total = Number(accommodation) + Number(services);

        if (lodash.isNaN(total)) {
            return NaN;
        }

        return displayNumberWithSpace(total);
    }

    const handleSelectChangeAdults = (event) => {
        const selectedAdults = Number(event.target.value);
        const totalPersons = selectedAdults + nbChilds;

        if (totalPersons > 22) {
            setNbChilds(22 - selectedAdults);
        }

        setNbAdults(selectedAdults);
    };

    const handleSelectChangeChilds = (event) => {
        const selectedChildren = Number(event.target.value);
        const totalPersons = nbAdults + selectedChildren;

        if (totalPersons > 22) {
            setNbAdults(22 - selectedChildren);
        }

        setNbChilds(selectedChildren);
    };

    const onSelectAccommodation = (value) => {
        const totalPeople = nbAdults + nbChilds;

        if (value === "home") {
            if (totalPeople > maxPeopleHome) {
                return Helper.toast(t('errors.tooManyPeopleHome'), "error");
            }
        } else if (value === "annex") {
            if (totalPeople > maxPeopleAnnex) {
                return Helper.toast(t('errors.tooManyPeopleAnnex'), "error");
            }
        }

        setAccommodationSelected(value);
    }

    const getNbMaxPeople = () => {
        if (!accommodationSelected || accommodationSelected === "domain") {
            return maxPeopleDomain;

        } else if (accommodationSelected === "home") {
            return maxPeopleHome;

        } else if (accommodationSelected === "annex") {
            return maxPeopleAnnex;
        }

    }

    const onSelectService = (value) => {
        setServicesSelected(value);
    }

    const getPriceService = (service) => {
        let total = 0;
        if (service.isPriceDefined) {
            if (service.isPricePerPersonOrForEveryone === "perPerson") {
                if (service.priceMustBePaidEach === "day") {
                    total += service.price * (Number(nbAdults) + Number(nbChilds)) * getNbDaysStay();
                } else {
                    total += service.price * (Number(nbAdults) + Number(nbChilds));
                }
            } else {
                if (service.priceMustBePaidEach === "day") {
                    total += service.price * service.quantity * getNbDaysStay();
                } else {
                    total += service.price * service.quantity;
                }
            }
            return displayNumberWithSpace(total);
        }
        return;
    }

    const getSubTotalServices = () => {
        let totalPrice = 0;
        for (const service of servicesSelected) {
            let price = getPriceService(service);
            if (price) {
                totalPrice += Number(String(getPriceService(service).replace(/\s/g, '')));
            }
        }
        return displayNumberWithSpace(totalPrice);
    }

    const clickDisplayFormContactDetails = () => {
        if (!stayDates.startDate || !stayDates.endDate) {
            return Helper.toast(t('errors.noDate'), "error");

        } else if (!nbAdults) {
            return Helper.toast(t('errors.noNbPersons'), "error");

        } else if (!accommodationSelected) {
            return Helper.toast(t('errors.noAccommodationSelected'), "error");

        } else {
            const tomorrow = new Date();
            tomorrow.setHours(0, 0, 0, 0);
            tomorrow.setDate(tomorrow.getDate() + 1);

            if (new Date(stayDates.startDate) < tomorrow || new Date(stayDates.endDate) < tomorrow) {
                return Helper.toast(t('errors.invalidDate'), "error");
            }
        }

        setDisplayFormContactDetails(true);
    }

    async function sendQuotation() {
        if (!stayDates.startDate || !stayDates.endDate) {
            return Helper.toast(t('errors.noDate'), "error");

        } else if (!nbAdults) {
            return Helper.toast(t('errors.noNbPersons'), "error");

        } else if (!accommodationSelected) {
            return Helper.toast(t('errors.noAccommodationSelected'), "error");

        } else {
            const tomorrow = new Date();
            tomorrow.setHours(0, 0, 0, 0);
            tomorrow.setDate(tomorrow.getDate() + 1);

            if (new Date(stayDates.startDate) < tomorrow || new Date(stayDates.endDate) < tomorrow) {
                return Helper.toast(t('errors.invalidDate'), "error");
            }

            // Check for overlap with blocked dates
            const stayStart = new Date(stayDates.startDate).getTime();
            const stayEnd = new Date(stayDates.endDate).getTime();

            for (let i = 0; i < blockedDates.length; i++) {
                const blockedStart = blockedDates[i].startDate;
                const blockedEnd = blockedDates[i].endDate;

                // Check if stay dates overlap with any blocked date range
                if (!(stayEnd <= blockedStart || stayStart >= blockedEnd)) {
                    return Helper.toast(t('errors.blockedDates'), "error");
                }
            }
        }

        const tmpErrorsPersonnalInfo = Validator.validatePersonnalInfoQuotationFormData(personnalInfoFormData, t);
        setErrorsPersonnalInfo(tmpErrorsPersonnalInfo);

        if (Object.keys(tmpErrorsPersonnalInfo).length === 0) {
            const quotation = {
                dates: {
                    startDate: stayDates.startDate,
                    endDate: stayDates.endDate,
                    creationDate: new Date(),
                    expirationDate: new Date(new Date().setMonth(new Date().getMonth() + 1))
                },
                mainInfos: {
                    status: 'draft',
                    language: i18n.language,
                    numberOfGuests: {
                        adults: nbAdults,
                        childs: nbChilds
                    }
                },
                prices: {
                    accommodationPrice: Number(Number(accommodationPrice * getNbDaysStay()).toFixed(2)),
                    typeOfAccommodation: accommodationSelected,
                    totalPrice: Number(String(getTotal().replace(/\s/g, ''))),
                    cleaning: Number(getPriceEndOfStayCleaningFees()),
                    discountLongAccommodation: discountLongAccommodation,
                    touristTax: getPriceTouristTax()
                },
                customer: {
                    lastname: personnalInfoFormData.lastname,
                    firstname: personnalInfoFormData.firstname,
                    email: personnalInfoFormData.email,
                    phone: personnalInfoFormData.phone
                },
                guests: [],
                address: {},
                services: servicesSelected.map(service => ({
                    slug: service.slug,
                    name: service.name['fr'],
                    quantity: service.quantity,
                    isPriceDefined: service.isPriceDefined,
                    price: service.isPriceDefined ? Number(service.price) : 0,
                    VAT: service.VAT,
                    priceMustBePaidEach: service.priceMustBePaidEach
                })),
                history: []
            };

            const response = await Api.saveQuotation(quotation);
            // Helper.handleApiResponse(response, t('responses.quotationSend'), t('responses.quotationError'));
            setDisplayFormContactDetails(false);
            // const responseEmail = await Api.sendQuotation(response.data.data);
            // Helper.handleApiResponse(responseEmail, t('responses.quotationSent'), t('responses.quotationError'));

            if (_.get(response, 'data.status')) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'quotationFormSubmissionSuccess',
                    formType: 'quotation'
                });
            }

            if (response.data?.status && response.data?.data?._id) {
                // const quotationId = response.data.data._id;
                // navigate(`/quotation/${quotationId}`);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                return Helper.toast(t('responses.quotationSend'), "success");
            } else {
                console.error("Quotation ID not found in the response.");
            }
        }
    }

    const twoYearsLater = new Date(new Date().setFullYear(new Date().getFullYear() + 2));

    return (
        <div>
            <Helmet>
                <title>{t('reservation.page')}</title>
                <meta name="description" content={t('reservation.description')} />
                <meta name="keywords" content={t('reservation.keywords')} />
            </Helmet>

            <div className="absolute top-0 w-full">
                <BackgroundImage image={imageHeaderBanner} alt={t('reservation.altBackground')} customHeight={"50vh"} />
            </div>

            <div className="max-w-[2000px] m-auto relative w-11/12 sm:w-11/12 md:w-5/6 lg:w-5/6 2xl:w-3/4 mx-auto mt-20 md:mt-40 flex flex-col md:flex-row gap-6 md:gap-10 mb-10">
                <div className="w-full md:w-3/5 bg-white py-6 px-4 md:px-8 h-fit shadow-lg">
                    <H2 title={t('reservation.quotation.title')} fontWeight='normal' />
                    <div className="text-xs font-normal mt-2">
                        <DescriptionSplit description={t('reservation.quotation.description')} />
                        <DescriptionSplit description={t('reservation.quotation.additionnalDescription')} className="font-bold" />
                    </div>

                    <H3 title={t('reservation.period.title')} fontWeight='normal' className="mt-6 mb-2" />
                    <div className="flex w-full">
                        <DateFieldInput label='stayDate' value={stayDates} minDate={new Date()} maxDate={twoYearsLater} onChange={handleDateChange} displayLabel={false} singleDate={false} blockedDates={blockedDates} className="rounded-none" />
                    </div>
                    <div className="w-full block md:flex">
                        <Select label="adults" name={t('formInputs.adult')} options={numberOptions("adult", getNbMaxPeople() - nbChilds)} value={nbAdults}
                            onChange={handleSelectChangeAdults} displayLabel={false} iconDisplayed={faUser} error={null} placeholder={'reservation.details.nbAdults'} className="rounded-none" />
                        <Select label="childs" name={t('formInputs.child')} options={numberOptions("child", getNbMaxPeople() - nbAdults)} value={nbChilds}
                            onChange={handleSelectChangeChilds} displayLabel={false} iconDisplayed={faUser} error={null} placeholder={'reservation.details.nbChildsWithAge'} className="rounded-none" />
                    </div>

                    <H3 title={t('reservation.accommodation.title')} fontWeight='normal' className="mt-8 mb-2" />
                    <DescriptionSplit description={t('reservation.accommodation.description')} />
                    <div className="my-4 flex flex-col justify-between gap-4">
                        <AccommodationDisplayed accommodation={displayedAccommodation} accommodationSelected={accommodationSelected} prices={prices} totalPeople={nbAdults + nbChilds} onChange={onSelectAccommodation} />
                    </div>

                    <H3 title={t('reservation.quotation.services.title')} fontWeight='normal' />
                    <div className="text-xs font-normal mt-2 mb-6">
                        <DescriptionSplit description={t('reservation.quotation.services.description')} />
                    </div>

                    <ListOfServices services={services} servicesSelectedsaved={servicesSelected} numberOfGuests={nbAdults + nbChilds} onChange={onSelectService} />
                </div>

                <div id="fixed-column" className="w-full md:w-2/5 h-auto flex flex-col">
                    <div className={`h-full flex flex-col ${divHeight + 160 > screenHeight ? "justify-end" : "justify-start"}`}>
                        <div ref={fixedColumnRef} className={`bg-white py-6 px-4 md:px-8 shadow-lg ${divHeight + 160 > screenHeight ? "bottom-6" : "top-[160px]"} sticky`}>
                            <div className="text-center">
                                <H3 title={t('reservation.details.title')} fontWeight="normal" />
                            </div>

                            <Hr />

                            <div>
                                <div className="flex gap-2 mb-2">
                                    <Icon icon={iconCalendar} />
                                    <H5 title={t('reservation.details.dates')} underline={true} />
                                </div>
                                {stayDates.startDate && stayDates.endDate ? (
                                    <span className="opacity-60">
                                        {new Date(stayDates.startDate).toLocaleDateString('fr-FR')} -
                                        {new Date(stayDates.endDate).toLocaleDateString('fr-FR')}
                                    </span>
                                ) : (
                                    <span className="opacity-60">
                                        {t('reservation.details.noDate')}
                                    </span>
                                )}
                            </div>

                            <div className="my-4">
                                <div className="flex gap-2 mb-2">
                                    <Icon icon={iconUsers} />
                                    <H5 title={t('reservation.details.nbAdults')} underline={true} />
                                </div>
                                <span className="opacity-60">
                                    {nbAdults || 0} {t('reservation.details.adults')}{nbAdults > 1 ? 's' : ''}
                                </span>
                            </div>

                            <div>
                                <div className="flex gap-2 mb-2">
                                    <Icon icon={iconUsers} />
                                    <H5 title={t('reservation.details.nbChilds')} underline={true} />
                                    <span className="opacity-50">{t('reservation.details.nbChildsAge')}</span>
                                </div>
                                <span className="opacity-60">
                                    {nbChilds || 0} {t('reservation.details.childs')}{nbChilds > 1 ? 's' : ''}
                                </span>
                            </div>

                            <Hr />

                            <div className="flex gap-2">
                                <Icon icon={iconAccommodation} />
                                <H5 title={t('reservation.details.accommodation')} underline="true" />
                            </div>
                            {accommodationSelected && (
                                <H5
                                    title={t(`reservation.accommodation.${accommodationSelected}.title`)}
                                    fontWeight="bold"
                                    className="mt-2 mb-4"
                                />
                            )}
                            <div className="mt-1 flex justify-between opacity-60">
                                <H6
                                    title={
                                        t('reservation.details.pricePerNight') +
                                        ` (${getNbDaysStay()} ${t('reservation.night')}${getNbDaysStay() > 1 ? 's' : ''
                                        })`
                                    }
                                />
                                <H6 title={accommodationPrice + '€'} />
                            </div>

                            {discountLongAccommodation !== 0 && (
                                <div className="pl-6 mt-1 flex justify-between opacity-60">
                                    <span className="text-sm">
                                        {t('reservation.details.discountLongAccommodation')}
                                    </span>
                                    <span className="text-sm">-{discountLongAccommodation}€</span>
                                </div>
                            )}

                            <div className="pl-6 mt-1 flex justify-between opacity-60">
                                <span className="text-sm">{t('reservation.details.laundry')}</span>
                                <span className="text-sm">{t('reservation.details.include')}</span>
                            </div>

                            <div className="pl-6 mt-1 flex justify-between opacity-60">
                                <span className="text-sm">{t('reservation.details.dailyCleaningFees')}</span>
                                <span className="text-sm">{t('reservation.details.include')}</span>
                            </div>

                            <div className="mt-1 flex justify-between opacity-60">
                                <H6 title={t('reservation.details.endOfStayCleaningFees')} />
                                <H6
                                    title={
                                        lodash.isNaN(getPriceEndOfStayCleaningFees())
                                            ? t('errors.error')
                                            : getPriceEndOfStayCleaningFees() + '€'
                                    }
                                />
                            </div>

                            <div className="mt-1 flex justify-between opacity-60">
                                <H6 title={t('reservation.details.touristTax')} />
                                <H6 title={getPriceTouristTax() + '€'} />
                            </div>

                            <div className="flex mt-4 justify-between">
                                <H4 title={t('reservation.details.subtotal')} />
                                <span>
                                    {lodash.isNaN(getSubTotalAccommodation())
                                        ? t('errors.error')
                                        : getSubTotalAccommodation() + '€'}
                                </span>
                            </div>

                            <Hr />

                            <div className="flex gap-2">
                                <Icon icon={iconServices} />
                                <H5 title={t('reservation.details.services')} underline="true" />
                            </div>

                            <div className="pl-6 flex justify-between opacity-60">
                                <span className="text-sm">{t('reservation.details.concierge')}</span>
                                <span className="text-sm">{t('reservation.details.include')}</span>
                            </div>

                            {servicesSelected.length > 0 && (
                                <>
                                    {servicesSelected.map((service, index) => (
                                        <div className="pl-6 flex justify-between opacity-60" key={index}>
                                            <span className="text-sm">
                                                {service.quantity}x&nbsp;
                                                {service.name[i18n.language] || service.name.en}
                                            </span>
                                            {service.isPriceDefined ? (
                                                <span className="text-sm">
                                                    {getPriceService(service) === '0'
                                                        ? t('reservation.services.free')
                                                        : getPriceService(service) + '€'}
                                                </span>
                                            ) : (
                                                <span className="text-sm">{t('reservation.details.toDefine')}</span>
                                            )}
                                        </div>
                                    ))}
                                </>
                            )}

                            <div className="flex mt-4 justify-between">
                                <H4 title={t('reservation.details.subtotal')} />
                                <span>{getSubTotalServices()}€</span>
                            </div>

                            <Hr />

                            <div className="flex mt-4 justify-between">
                                <H3 title={t('reservation.details.total')} />
                                <span className="font-bold">
                                    {lodash.isNaN(getTotal())
                                        ? t('errors.error')
                                        : getTotal() + '€'}
                                </span>
                            </div>

                            {!displayFormContactDetails ? (
                                <>
                                    <div className="mt-10 text-center w-full">
                                        <Button
                                            onClick={clickDisplayFormContactDetails}
                                            text={t('reservation.details.getQuotation')}
                                            className="w-full"
                                            disabled={lodash.isNaN(getTotal())}
                                            error={t('errors.priceError')}
                                        />
                                    </div>
                                    <span className="mt-4 block text-center w-full">
                                        {t('reservation.details.contactFinalizeQuotation')}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <Hr />
                                    <H5
                                        title={t(t('reservation.details.descriptionContactForm'))}
                                        className="text-center mb-8"
                                    />
                                    <PersonnalInfoForm
                                        customer={personnalInfoFormData}
                                        errors={errorsPersonnalInfo}
                                        updatePersonnalInfoFormData={updatePersonnalInfoFormData}
                                        fullWidth={true}
                                    />
                                    <Button onClick={sendQuotation} text={t('reservation.details.validate')} className="mt-8 w-full" />
                                </>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}