import React from 'react';
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

// Components - Sections
import HeaderVideo from './sections/header-video';
import SectionPhotoGallery from './sections/photo-gallery';
import UniquePlace from './sections/unique-place';
import Icons from './sections/icons';
import Gaia from './sections/gaia';
import Hestia from './sections/hestia';
import Events from './sections/events';
import SectionPartners from './sections/logos-partners.jsx';
import SectionTestimonials from './sections/carousel-testimonials.jsx';
import DomainOverview from './sections/domain-overview';
import SectionPublications from './sections/publications';

export default function Home() {
    const { t } = useTranslation();

    return (
        <div className='bg-backgroundColor'>
            <Helmet>
                <title>{t('home.page')}</title>
                <meta name="description" content={t('home.description')} />
                <meta name="keywords" content={t('home.keywords')} />
            </Helmet>

            <HeaderVideo />

            <SectionPhotoGallery />

            <div className='max-w-[2000px] text-center m-auto'>
                <DomainOverview />

                <UniquePlace />

                <Gaia />

                <Hestia />

                <Icons />

                <Events />

                <SectionPartners
                    title={t('home.sectionOurPartners.title')}
                    data={t('home.sectionOurPartners.logos', { returnObjects: true })}
                />
            </div>

            <SectionTestimonials
                title={t('home.sectionTestimonials.title')}
                data={t('home.sectionTestimonials.data', { returnObjects: true })}
            />

            <SectionPublications />
        </div>
    );
}